'use strict'

import * as dot from 'dot-object'
import * as _ from 'lodash'
import configurazioniService from '../../../services/configurazioniService'
import prodottiService from '@/services/prodottiService'
import movimentiService from '@/services/movimentiService'
import moment from 'moment'

export const creaMovimenti = async function (dati, datiDefault, valoriDefault, numeroMovimento, fornitore, dataMovimento) {
  const prodotti = dati.map(pr => {
    const pobj = dot.object({ ...pr })
    delete pobj.appoggio // toglie tutti i dati che non devono andare nel db prodotti
    _.merge(pobj, datiDefault)
    return pobj
  })
  const dataJS = moment(dataMovimento, 'DD/MM/YYYY').utcOffset(0, true).format()
  const movimenti = dati.map(pr => {
    const pobj = dot.object(pr)
    _.merge(pobj, datiDefault)
    const prezzo = pr.prezzo[valoriDefault.listinoVendita].base
    const acquisto = pr.magazzino.prezzoAcquisto
    return {
      prodotto: {
        codice: pr.codice + String(pr.varianti.variante1.codice) + String(pr.varianti.variante2.codice),
        quantita: pr.appoggio.quantita,
        um: pr.magazzino.unitaMisura,
        prezzoUnitario: prezzo,
        prezzo: +(prezzo * pr.appoggio.quantita).toFixed(2),
        aliquota: pr.appoggio.aliquota || datiDefault.datiFiscali.aliquoteIva[0].valore, // TODO: prendere da modello se diverso. Va preso da configurazioni
        prezzoAcquisto: acquisto,
        valoreacquisto: +(acquisto * pr.appoggio.quantita).toFixed(2)
      },
      quantita: pr.appoggio.quantita,
      // valore: +(prezzo * pr.appoggio.quantita).toFixed(2),
      valore: +(acquisto * pr.appoggio.quantita).toFixed(2), // va valutato in base al tipo di movimento fatto
      riferimento: {
        tipo: 'importDati',
        id: numeroMovimento,
        data: dataJS
      },
      anagrafica: {
        cliente: '', // aggiungere esterno, solo se presente nei dati
        fornitore: {
          codice: fornitore.codice,
          descrizione: fornitore.ragioneSociale
        }
      },
      custom: pr.custom
    }
  })

  const prodottiGiacenza = dati.map(pr => {
    const pobj = dot.object({ ...pr })
    pobj.ultimoAcquisto = {
      data: dataJS,
      quantita: pr.appoggio.quantita,
      fornitore: fornitore.codice,
      fornitoreDescrizione: fornitore.ragioneSociale,
      // prezzo: pr.prezzo.L1.base,
      prezzo: pr.magazzino.prezzoAcquisto, // TODO: verificare
      movimento: numeroMovimento
    }
    return pobj
  })
  try {
    if (prodotti && prodotti.length > 0) {
      let start = 0
      let end = 0
      do {
        end = start + 25
        const sub = prodotti.slice(start, end)
        console.log('prodotti:', start, end)
        await prodottiService.creaProdottiMultipliTestBarcode(sub, 'import: ' + numeroMovimento, true, false)

        start += 25
      } while (end < prodotti.length)
    }
    var parametri
    if (movimenti && movimenti.length > 0) {
      const magazzino = valoriDefault.magazzinoMovimento
      const causale = valoriDefault.causaleMovimento
      const causali = await configurazioniService.getConfigurazione('magazzino_causali', 'causali')
      parametri = causali.find(x => x.codice === causale)

      let start = 0
      let end = 0
      do {
        end = start + 25
        const sub = movimenti.slice(start, end)
        await movimentiService.creaMovimentiMultipli(magazzino, parametri, sub)
        start += 25
      } while (end < movimenti.length)
    }
    if (prodottiGiacenza && prodottiGiacenza.length > 0) {
      let start = 0
      let end = 0
      do {
        end = start + 25
        const sub = prodottiGiacenza.slice(start, end)
        console.log('prodottiGiacenza:', start, end)
        await prodottiService.aggiornaGiacenzaMultipla(parametri, sub, true) // Parametro taglia/Colore deve arrivare da import

        start += 25
      } while (end < prodottiGiacenza.length)
      return true
    }
    // import categorieService from '@/services/categorieService.js'
    return []
  } catch (error) {
    // winston.error(`creaMovimento ordine: ${error}`)
    console.log(`creaMovimento ordine: ${error}`)
    return []
  }
}
