'use strict'
// const winston = require('../config/winston');
import prodotti from '@/services/prodottiService.js'
import configurazioni from '@/services/configurazioniService.js'

async function generaCategoria(nomeConfigurazione, subCategoria, tipo, nomeCampoSorgente) {
  try {
    let elenco = await prodotti.estraeCategorie(nomeCampoSorgente)
    if (tipo) {
      elenco = elenco.map(x => { return { codice: x.codice, descrizione: x.descrizione, tipo: tipo, ordine: 0 } })
    }
    await configurazioni.aggiornaCategorie(nomeConfigurazione, subCategoria, elenco)
  } catch (error) {
    // winston.error(`generaCategoria, errore: ${error}`)
    console.log(`generaCategoria, errore: ${error}`)
  }
}

export default {
  generaCategoria
}
