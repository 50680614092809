<template>
  <div class="import">
    <van-button style="float:right;" icon="close" type="default" @click="onClose">Esci</van-button><h1>Gestione importazione dati</h1>
    <van-tabs v-model="activeName" @change="onChangeTab" class="" type="card">
      <van-tab title="Import prodotti" name="prodotti">
        <van-form>
          <van-row>
            <van-col span="16">
              <van-field
                readonly
                clickable
                :value="modello"
                name="modelloProdotto"
                ref="modelloProdotto"
                label="Modello"
                placeholder="Modello file"
                @click="showModelloProdotto = true"
              />
              <van-popup v-model="showModelloProdotto" position="bottom" :style="getStilePopup()" visible-item-count="8">
                <van-picker show-toolbar title="Modello File" :columns="columnsModelliProdotto" @confirm="onConfirmModelloProdotto" @cancel="showModelloProdotto = false"/>
              </van-popup>
            </van-col>
          </van-row>
          <van-row>
            <van-col span="6">
              <van-field
              v-model="nomeImportProdotti"
              name="nomeImportProdotti"
              label="Descrizione"
              placeholder="Descrizione/Numero documento"
              :rules="[{ required: false, message: 'La descrizione è richiesta' }]"
            />
            </van-col>
            <van-col span="5">
<!--               <van-field
                v-model="fornitoreImportProdotti"
                name="fornitoreImportProdotti"
                label="Fornitore"
                placeholder="Fornitore"
                :rules="[{ required: false, message: 'Il fornitore è richiesto' }]"
              /> -->
              <van-field
                readonly
                clickable
                :value="fornitoreDescrizione"
                name="fornitoreImportProdotti"
                ref="fornitoreImportProdotti"
                label="Fornitore"
                placeholder="Fornitore"
                @click="showFornitore = true"
              />
              <van-popup v-model="showFornitore" position="bottom" :style="getStilePopup()" visible-item-count="8">
                <van-picker show-toolbar title="Fornitore" :columns="columnsFornitori" @confirm="onConfirmFornitore" @cancel="showFornitore = false"/>
              </van-popup>
            </van-col>
             <van-col span="5">
              <van-cell-group>
              <van-field
                readonly
                clickable
                :border="false"
                name="dataImportProdotti"
                :value="dataImportProdotti"
                label="Data"
                placeholder="Data movimento"
                :rules="[{ required: false, message: 'La data è richiesta' }]"
                @click="showCalendar = true"
              />
              <van-calendar v-model="showCalendar" @confirm="onConfirmData" :style="getStilePopup()" :show-confirm="false" />
              </van-cell-group>
            </van-col>
            <van-col span="5">
              <van-uploader :preview-image="false" :before-read="beforeRead" :after-read="afterRead" accept=".csv, .xls, .xlsx">
                <van-button icon="plus" type="primary" :loading="isLoading" loading-text="Caricamento dati...">Scegli un file da caricare</van-button>
              </van-uploader>
            </van-col>
            <van-col span="3">
              <van-button type="info" @click="aggiornaCategorie" :loading="isAggiornaCategorie" loading-text="Aggiornamento...">Aggiorna categorie</van-button>
            </van-col>
          </van-row>
          <hr>
          <van-row>
            <van-col span="16">
              <van-field
              :value="nomeFileCaricato"
              readonly
              label="File"
              />
            </van-col>
            <van-col span="8">
              <van-button class="mr-10" v-if="showImporta" type="info" :loading="isImportaProdotti" loading-text="Importazione..." @click="importaProdotti">Importa Prodotti</van-button>
              <van-button v-if="showImporta" type="warning" @click="annullaSelezione">Annulla selezione</van-button>
            </van-col>
          </van-row>
        </van-form>
      </van-tab>
      <van-tab title="Import clienti" name="clienti">
        <van-form>
          <van-row>
            <van-col span="16">
              <van-field
                v-model="nomeImportClienti"
                name="nomeImportClienti"
                label="Descrizione"
                placeholder="Descrizione import"
                :rules="[{ required: false, message: 'La descrizione è richiesta' }]"
              />
            </van-col>
            <van-col span="8">
              <van-uploader :preview-image="false" :before-read="beforeRead" :after-read="afterRead" accept=".csv, .xls, .xlsx">
              <van-button icon="plus" type="primary" :loading="isLoading" loading-text="Caricamento dati...">Scegli un file da caricare</van-button>
            </van-uploader>
           </van-col>
          </van-row>
          <hr>
          <van-row>
            <van-col span="16">
              <van-field :value="nomeFileCaricato" readonly label="File"/>
            </van-col>
            <van-col span="8">
              <van-button class="mr-10" v-if="showImporta" type="info" :loading="isImportaClienti" loading-text="Importazione..." @click="importaClienti">Importa Clienti</van-button>
              <van-button v-if="showImporta" type="warning" @click="annullaSelezione">Annulla selezione</van-button>
            </van-col>
          </van-row>
        </van-form>
      </van-tab>
      <van-tab title="Import tabelle generiche" name="tabelle">
        <van-form>
          <van-row>
            <van-col span="10">
              <van-field
                  v-model="tipoTabella"
                  name="tipoTabella"
                  label="Tipo Tabella"
                  placeholder="Tipo Tabella = nome foglio Excel (es. istat_comuni)"
                  :rules="[{ required: true, message: 'Il Tipo è richiesto' }]"
                />
            </van-col>
            <van-col span="10">
              <van-field
                  v-model="chiaveTabella"
                  name="chiaveTabella"
                  label="Chiave"
                  placeholder="Chiave (es. regione_provincia_comune, case sensitive su nomi colonne)"
                  :rules="[{ required: true, message: 'La Chiave è richiesta' }]"
                />
            </van-col>
            <van-col span="4">
              <van-uploader :preview-image="false" :before-read="beforeRead" :after-read="afterRead" accept=".csv, .xls, .xlsx">
                <van-button icon="plus" type="primary" :loading="isLoading" loading-text="Caricamento dati...">Scegli un file da caricare</van-button>
              </van-uploader>
            </van-col>
          </van-row>
          <hr>
          <van-row>
            <van-col span="16">
              <van-field :value="nomeFileCaricato" readonly label="File"/>
            </van-col>
            <van-col span="8">
              <van-button v-if="showImporta" type="info" :loading="isImportaTabella" loading-text="Importazione..." @click="importaTabella">Importa Tabella</van-button>
              <van-button v-if="showImporta" type="warning" @click="annullaSelezione">Annulla selezione</van-button>
            </van-col>
          </van-row>
        </van-form>
        <!--         <div>
          <label for="tipoTabella">Tipo Tabella = nome foglio Excel (es. istat_comuni)</label>
          <input type="text" name="tipoTabella" v-model="tipoTabella"/>
        </div>
        <div>
          <label for="chiaveTabella">Chiave (es. regione_provincia_comune, case sensitive su nomi colonne)</label>
          <input type="text" name="chiaveTabella" v-model="chiaveTabella"/>
        </div>
        <label for="tabella">Scegli un file da caricare (intestazioni su prima riga)</label>
        <input type="file" id="tabella" name="tabella"  accept=".xls, .xlsx, .csv" @change="handleFile">
        <button @click="importaTabella">Importa Tabella</button>
        <span v-show="tabellaImportata">Tabella importata correttamente</span>
        <span v-show="tabellaErrore" style="color: red;">{{ messaggioErrore }}</span> -->
      </van-tab>
    </van-tabs>
    <div class="html-container">
      <h3>Preview </h3>
      <van-tabs v-model="activePreview" type="card" color="green">
        <van-tab title="Excel" style="overflow: scroll;">
          <div class="preview" v-html="htmlPreview"></div>
        </van-tab>
        <van-tab title="Modello">
          <div style="width: 200%;">
            <van-row type="flex" justify="space-between" style="font-weight: bold;min-height: 28px;">
              <van-col v-for="(cl, idx) in colonneModello" :key=idx span="1">{{cl}}</van-col>
            </van-row>
            <van-row v-for="(riga, index) in datiImport[activeName]" :key="index" type="flex" justify="space-between">
              <van-col v-for="(cl, idx) in Object.values(riga)" :key=idx span="1">{{cl}}</van-col>
            </van-row>
          </div>
        </van-tab>
      </van-tabs>
      <!-- :span="Math.round(24/Object.keys(riga).length)" -->
      <!-- <div class="preview" v-html="htmlPreview"></div> -->
<!--       <div class="preview"></div>
      <van-row v-for="(riga, index) in datiImport[activeName]" :key="index">
        <van-col v-for="(cl, idx) in Object.values(riga)" :key=idx>{{cl}}</van-col>
      </van-row> -->
    </div>
  </div>
</template>

<script>
// Elaborazione formule su Excel
// https://github.com/fabiooshiro/xlsx-calc
// Creazione Excel --> https://spin.atomicobject.com/2018/11/28/nodejs-excel-file-generation/
// https://github.com/exceljs/exceljs

// Lettura/Scrittura Excel
// https://github.com/SheetJS/sheetjs

/*
  TODO: Fare file di configurazione per tipologia di import,
  specificando nomi campi, colonne, etc.
  (su tabelle anche nome campo se colonna diversa es CodiceRegione --> codiceRegione)
*/

import * as _ from 'lodash'
import * as XLSX from 'xlsx'
import * as dot from 'dot-object'
import moment from 'moment'
import clienti from '@/services/clientiService.js'
// import prodotti from '@/services/prodottiService.js'
import tabelle from '@/services/tabelleService.js'
import configurazioni from '@/services/configurazioniService.js'
import categorie from '@/services/categorieService'
import { dynamicSortMultiple } from '@/components/helpers.js'
import { creaMovimenti } from './tipi/prodotti.js'

// var XLSX_CALC = require('xlsx-calc')

export default {
  data() {
    return {
      activeName: 'prodotti',
      nomeFile: '',
      showImporta: false,
      showCalendar: false,
      isLoading: false,
      isImportaProdotti: false,
      isImportaClienti: false,
      isImportaTabella: false,
      isAggiornaCategorie: false,
      datiImport: {},
      clientiImportati: false,
      prodottiImportati: false,
      tabellaImportata: false,
      tabellaErrore: false,
      messaggioErroreDati: 'Dati non trovati o nome foglio errato',
      messaggioErrore: '',
      nomeImportClienti: '',
      nomeImportProdotti: '',
      fornitoreImportProdotti: '',
      dataImportProdotti: moment().format('DD/MM/YYYY'),
      tipoTabella: '',
      chiaveTabella: '',
      htmlPreview: null,
      activePreview: 1,
      /*       modelloProdotto: '',
      modelloProdottoDescrizione: '', */
      modelloItem: null,
      modello: '',
      modelloDescrizione: '',
      showModelloProdotto: false,
      fornitoreItem: null,
      fornitore: '',
      fornitoreDescrizione: '',
      showFornitore: false,
      modelli: null,
      colonneModello: [],
      fornitori: null
    }
  },
  async mounted() {
    this.modelli = await configurazioni.getConfigurazione('import_dati', 'modelli')
    this.fornitori = await clienti.getFornitori()
  },
  computed: {
    nomeFileCaricato() {
      return this.nomeFile
    },
    columnsModelliProdotto() {
      if (!this.modelli) return []
      const v = this.modelli.prodotti
      return v.sort(dynamicSortMultiple('ordine', 'descrizione')).map(el => el.descrizione)
    },
    columnsFornitori() {
      if (!this.fornitori) return []
      const v = this.fornitori.map(x => { return { descrizione: x.ragioneSociale } })
      return v.sort(dynamicSortMultiple('descrizione')).map(el => el.descrizione)
    },
    intestazioniColonneModello() {
      const dati = this.datiImport[this.activeName]
      if (!dati || dati.length === 0) {
        return []
      }
      const riga = dati[0]
      const keys = Object.keys(riga)
      const mdColonne = this.modelloItem ? this.modelloItem.colonne : []
      let k = 0
      const colonne = keys.map(x => {
        const s = x.split('.')
        return mdColonne && mdColonne.length > 0 ? mdColonne[k++].label : s[s.length - 1].toUpperCase()
      })
      return colonne
    }
  },
  methods: {
    getStilePopup() {
      return 'width: 500px; height: "50%";'
      /*       var cont = document.querySelector('.tab-popup-funzioni')
      if (cont) {
        return `height: "50%"; width: ${cont.offsetWidth}px`
      } else {
        return 'height: "50%";'
      } */
    },
    /*     columnsModello(tipo) {
      const v = this.modelli[tipo]
      return v ? v.sort(dynamicSortMultiple('ordine', 'descrizione')).map(el => el.descrizione) : []
    }, */
    onConfirmModelloProdotto(value) {
      this.showModelloProdotto = false
      const modello = this.modelli.prodotti.find(x => x.descrizione === value)
      this.modelloItem = modello
      this.modello = modello.codice
      // this.modelloProdotto = modello.codice
      this.modelloDescrizione = modello.descrizione
    },
    onConfirmFornitore(value) {
      this.showFornitore = false
      const fornitore = this.fornitori.find(x => x.ragioneSociale === value)
      this.fornitoreItem = fornitore
      this.fornitore = fornitore.codice
      this.fornitoreDescrizione = fornitore.ragioneSociale
    },
    onConfirmData(value) {
      const data = moment(value).format('DD/MM/YYYY')
      this.showCalendar = false
      this.dataImportProdotti = data
    },
    str2ab(str) {
      var buf = new ArrayBuffer(str.length)
      var bufView = new Uint8Array(buf)
      for (var i = 0, strLen = str.length; i < strLen; i++) {
        bufView[i] = str.charCodeAt(i)
      }
      return buf
    },
    beforeRead() {
      this.isLoading = true
      return true
    },
    afterRead(file) {
      this.nomeFile = file.file.name
      const data = atob(file.content.substring(file.content.indexOf(',') + 1))
      var fileData = this.str2ab(data)
      const book = XLSX.read(fileData, { type: 'array' })
      const sheetNum = 1
      const sheetName = book.SheetNames[+sheetNum - 1]
      const sheet = book.Sheets[sheetName]
      let dati = this.dataFromSheet(sheet)
      const datiPuliti = []
      if (dati) {
        const toDelete = Object.keys(dati[0]).filter(x => x.startsWith('__EMPTY'))
        if (toDelete) {
          dati.forEach(riga => {
            datiPuliti.push(_.omit(riga, toDelete))
          })
        }
        dati = null
      }
      // ****************  Elaborare i dati in base al modello  **********************
      const datiModello = []
      if (this.modello && datiPuliti.length > 0) {
        const modello = this.modelloItem
        let idxRiga = modello.prima_riga_dati || 2
        datiPuliti.forEach(riga => {
          const rigaM = {}
          modello.colonne.forEach(col => {
            if (col.colonnaSorgente) {
              rigaM[col.campo] = riga[col.colonnaSorgente]
            } else {
              const idx = col.colonna - 1
              /*               const keys = Object.keys(riga)
              const key = keys[idx]
              let valore = riga[key] */
              const colName = `${String.fromCharCode(65 + idx)}${idxRiga}`
              var cell = sheet[colName]
              let valore = (cell ? cell.v : '')
              if (col.fattore && valore) {
                valore *= col.fattore
              }
              if (col.offset) {
                valore += col.offset
              }
              if (col.decimali && valore) {
                valore = +parseFloat(valore).toFixed(col.decimali)
              }
              rigaM[col.campo] = valore
            }
            if (typeof rigaM[col.campo] === 'string') {
              rigaM[col.campo] = rigaM[col.campo].trim()
            }
          })
          datiModello.push(rigaM)
          idxRiga++
        })
      }
      // *****************************************************************************
      // this.datiImport[sheetName] = datiModello.length > 0 ? datiModello : datiPuliti
      this.datiImport[this.activeName] = datiModello.length > 0 ? datiModello : datiPuliti
      this.colonneModello = this.intestazioniColonneModello
      this.htmlPreview = this.htmlFromSheet(sheet)
      this.isLoading = false
      this.showImporta = true
    },
    onChangeTab(name) {
      this.annullaSelezione()
    },
    annullaSelezione() {
      this.isLoading = false
      this.nomeFile = ''
      this.showImporta = false
      this.htmlPreview = null
      this.datiImport = {}
    },
    // Gestire i Listini come import: codiceProdotto, listino, cliente, prezzo
    async importaClienti() {
      if (this.datiImport.clienti) {
        this.importaClienti = true
        const dati = this.datiImport.clienti
        const pattern = /[^a-zA-Z0-9-]+/gi
        for (const dd of dati) {
          if (!dd.ragioneSociale) continue
          if (!dd.codice) {
            dd.codice = dd.ragioneSociale.trim().replace(/\s\s+/g, ' ').replace(pattern, '_').toLowerCase()
          }
          if (!dd._id) {
            dd._id = dd.codice
          }
          if (dd.amministrativi) {
            if (dd.amministrativi.cliente === 'true') {
              dd.amministrativi.cliente = true
              const nc = dd.ragioneSociale.toLowerCase().replace(/(^|\s)\S/g, function(t) { return t.toUpperCase() }).split(' ')
              const titolare = {
                tipo: 'titolare',
                nome: nc.slice(0, -1).join(' '),
                cognome: nc[nc.length - 1],
                telefono: '',
                cellulare: dd.fidelity.cellulare,
                email: '',
                pec: ''
              }
              dd.contatti = [titolare]
            } else {
              dd.amministrativi.cliente = false
            }
            if (dd.amministrativi.fornitore === 'true') {
              dd.amministrativi.fornitore = true
            } else {
              dd.amministrativi.fornitore = false
            }
          }
        }
        for (const el of dati) {
          await clienti.creaCliente(el)
        }
        this.clientiImportati = true
        this.$toast('Clienti importati correttamente', { type: 'success' })
        this.importaClienti = false
      }
    },
    async importaProdotti() {
      if (this.datiImport.prodotti) {
        this.isImportaProdotti = true
        const dati = this.datiImport.prodotti
        // datiDefault = dati da inserire direttamente nel prodotto
        // valoriDefault = dati utilizzati nel contesto (es movimenti)
        await creaMovimenti(dati, this.modelloItem.datiDefault, this.modelloItem.valoriDefault, this.nomeImportProdotti, this.fornitoreItem, this.dataImportProdotti)
        this.prodottiImportati = true
        this.$toast('Prodotti importati correttamente', { type: 'success' })
        this.isImportaProdotti = false
      }
    },
    async importaTabella() {
      this.tabellaImportata = false
      this.messaggioErrore = 'Chiave non trovata: '
      if (!this.tipoTabella || !this.chiaveTabella || this.datiImport[this.tipoTabella]) {
        this.isImportaTabella = true
        this.tabellaErrore = false
        const dati = this.datiImport[this.tipoTabella]
        await this.asyncSome(dati, async (el) => {
          const riga = { ...el }
          riga.tipo = this.tipoTabella
          if (this.chiaveTabella) {
            const chiavi = this.chiaveTabella.split('_')
            riga._id = this.tipoTabella
            chiavi.some(ch => {
              const chiave = riga[ch] || ''
              if (chiave) {
                riga._id = riga._id + '_' + String(chiave.split(' ').join('_')).toLowerCase()
              } else {
                this.messaggioErrore += `${ch}, `
                this.tabellaErrore = true
              }
            })
          }
          if (!this.tabellaErrore) {
            console.log('riga:', riga)
            await tabelle.creaRigaTabella(riga)
            this.tabellaImportata = true
            this.$toast('Tabella importata correttamente', { type: 'success' })
            this.isImportaTabella = false
          } else {
            this.$toast('Errore importazione tabella:' + this.messaggioErrore, { type: 'fail' })
            this.isImportaTabella = false
            return true // esce dal ciclo
          }
        })
      } else {
        this.messaggioErrore = this.messaggioErroreDati
        this.tabellaErrore = true
      }
    },
    async aggiornaCategorie() {
      if (this.modelloItem && this.modelloItem.creaCategorie) {
        this.isAggiornaCategorie = true
        for (const cat of this.modelloItem.creaCategorie) {
          await categorie.generaCategoria(cat.nomeConfigurazione, cat.subCategoria, cat.tipo, cat.nomeCampoSorgente)
        }
        this.$toast('Categorie aggiornate correttamente', { type: 'success' })
        this.isAggiornaCategorie = false
      }
    },
    async asyncSome (arr, predicate) {
      for (const e of arr) {
        if (await predicate(e)) return true
      }
      return false
    },
    dataFromSheet(sheet) {
      const json = XLSX.utils.sheet_to_json(sheet)
      return this.dataFromJSON(json)
    },
    jsonFromSheet(sheet) {
      return XLSX.utils.sheet_to_json(sheet)
    },
    htmlFromSheet(sheet) {
      return XLSX.utils.sheet_to_html(sheet, { header: '', footer: '' })
    },
    dataFromJSON(json) {
      _.forEach(json, row => {
        dot.object(row)
      })
      return json
    },
    onClose() {
      this.$router.push('/')
    }
  }
}
</script>

<style lang="scss" scoped>
  #app {
    padding: 20px;
  }
  .preview {
    white-space: nowrap;
    overflow: scroll;
    text-overflow:ellipsis;

    & td {
      padding-right: 15px;
    }
    & tr:first-child {
      font-weight: bold;
      background-color: #d6d6d6;
    }

    & tr:first-child td:empty {
      background: #fffb00;
    }

  }

  .html-container {
    margin-top: 0px;
    width: 100%;
    height: 72vh;
    overflow: scroll;
    background: #ffffff;
    border-left: 1px solid #cccccc;
    border-right: 1px solid #cccccc;
    border-bottom: 1px solid #cccccc;
    padding: 30px;
    box-sizing: border-box;
  }

  .van-tabs__content {
    padding: 20px;
    background: #ffffff;
    background: #ffffff;
    border-left: 1px solid #cccccc;
    border-right: 1px solid #cccccc;
    border-bottom: 1px solid #cccccc;
  }

  .van-tabs__nav--card {
    border-left: 1px solid #cccccc;
    border-right: 1px solid #cccccc;
    border-top: 1px solid #cccccc;
    border-bottom: 1px solid #ffffff;
  }

  .van-tabs__nav--card .van-tab {
    color: #333333;
    font-size: 14px;
    border-right: 1px solid #cccccc;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 0px;
    background: #ececec;
    border-bottom: 1px solid #cccccc;
  }

  .van-tabs__nav--card .van-tab.van-tab--active {
    color: #000000;
    background-color: #ffffff;
    border-bottom: 1px solid #ffffff;
    font-size: 16px;
    margin-top: 7px;
  }

  .van-field__control {
    background-color: #ececec;
    padding-left: 20px;
    }

   .van-col--16 .van-cell, .van-col--10 .van-cell {
    padding: 0px 16px;
    font-size: 16px;
    line-height: 44px;
    background-color: #fff;
  }

  .mr-10 {margin-right: 10px;}
  hr {opacity: 0.2;}
  table {width: 100%;}
  .preview td {
    padding: 10px;
}

.preview tr td:last-child {
    width: 1%;
    white-space: nowrap;
}

</style>
